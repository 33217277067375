import MeadowContainer from "../../container";
import {
    amountPaddingSx,
    checkboxCardSx,
    checkboxSx,
    continueButtonSx,
    continueContainerSx,
    dollarSignSx,
    innerContainerSx,
    inputSx,
    meadowContainerSx,
    otherAmountCheckboxContainerSx,
    otherAmountContainerSx,
    radioWrapperBorderTopSx,
    radioWrapperSx,
} from "./styles";
import { Box, Button, Card, FormLabel, InputAdornment, Radio, TextField } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { formatter, formatterNoDollarSign } from "../../billing/utilities";
import useBreakpoint from "../../../hooks/useBreakpoint";
import SecurePayment from "./../SecurePayment";
import { PaymentContext } from "../../../providers/PaymentsProvider";
import { NumericFormatter } from "./../NumericFormatter";
import { PaymentMode } from "../../../reducers/PaymentsReducer";
import { SchoolService } from "../../../services/SchoolService";
import LoadingDialog from "../../modal/LoadingDialog";
import DisabledInput from "./DisabledInput";
import { ConfigContext } from "../../../context/ConfigContext";

type CheckboxOption = {
    paymentMode: PaymentMode;
    label: string;
    checkboxValue: string;
    amountDisplayValue: number;
    amountValue: number;
};

export default function PaymentAmount() {
    const {
        currentStudentId,
        setPartialPaymentAmount,
        setPaymentMode,
        getCurrentStudent,
        setFinixFraudToken,
        getStudentSchool,
    } = useContext(PaymentContext);
    const { isMobile } = useBreakpoint();
    const navigate = useNavigate();
    const { config } = useContext(ConfigContext);
    const { getPaymentPlanEnrollment, setAmountToPay, setStudentSchool, school } =
        useContext(PaymentContext);
    const isEdit = new URLSearchParams(location.search).get("edit") == "true";
    const [isAmountError, setIsAmountError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [displayAmountToPay, setDisplayAmountToPay] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [schoolService] = useState(() => new SchoolService());
    const [enableDecimalFormatting, setEnableDecimalFormatting] = useState<boolean>(false);
    const currentStudent = getCurrentStudent();
    const currentSchool = getStudentSchool();
    const paymentPlanEnrollment = getPaymentPlanEnrollment();

    const [checkboxOptions, setCheckboxOptions] = useState<CheckboxOption[]>([]);
    const [otherAmountMinimum, setOtherAmountMinimum] = useState<number>(0);
    const [otherAmountMaximum, setOtherAmountMaximum] = useState<number>(0);
    const isStudentAccountDisabled = currentStudent?.status === "disabled";
    if (!currentStudentId || !currentStudent) {
        return <Navigate to="/signin" />;
    }

    /**
     * Sets up the checkbox options that will display on this page
     */
    useEffect(() => {
        const options: CheckboxOption[] = [];

        if (paymentPlanEnrollment) {
            // if user has a past due balance, we do not want to show the One-time start fee option
            if (paymentPlanEnrollment.feePlusPreviousBalance) {
                options.push({
                    paymentMode: "paymentPlanFeePlusPreviousBalance",
                    label: "Fee + Previous balance",
                    checkboxValue: "Fee + Previous balance",
                    amountDisplayValue: paymentPlanEnrollment.feePlusPreviousBalance,
                    amountValue: paymentPlanEnrollment.previousBalance,
                });
            } else {
                options.push(
                    {
                        paymentMode: "paymentPlanFee",
                        label: "One-time start fee",
                        checkboxValue: "One-time start fee",
                        amountDisplayValue: paymentPlanEnrollment.fee,
                        amountValue: 0,
                    },
                    {
                        paymentMode: "paymentPlanFeePlusFirstPayment",
                        label: "Fee + First payment",
                        checkboxValue: "Fee + First payment",
                        amountDisplayValue: paymentPlanEnrollment.feePlusFirstInstallment ?? 0,
                        amountValue: paymentPlanEnrollment.installments[0].totalAmount,
                    }
                );
            }
        } else {
            const showPastDuePaymentOptions =
                currentStudent.status === "pastDue" &&
                currentStudent.nextPaymentAmount > 0 &&
                config?.shouldShowDueDates;

            if (showPastDuePaymentOptions) {
                options.push({
                    paymentMode: "pastDue",
                    label: "Past due",
                    checkboxValue: "Past due",
                    amountDisplayValue: currentStudent.overduePaymentAmount,
                    amountValue: currentStudent.overduePaymentAmount,
                });
                options.push({
                    paymentMode: "pastDuePlusNextPayment",
                    label: "Past due + Next payment",
                    checkboxValue: "Past due + Next payment",
                    amountDisplayValue:
                        currentStudent.overduePaymentAmount + currentStudent.nextPaymentAmount,
                    amountValue:
                        currentStudent.overduePaymentAmount + currentStudent.nextPaymentAmount,
                });
            } else {
                options.push({
                    paymentMode: "paymentDue",
                    label: "Payment due",
                    checkboxValue: "Payment due",
                    amountDisplayValue: config?.shouldShowDueDates
                        ? currentStudent.overduePaymentAmount || currentStudent.nextPaymentAmount
                        : currentStudent.overduePlusNextPaymentAmount,
                    amountValue: config?.shouldShowDueDates
                        ? currentStudent.overduePaymentAmount || currentStudent.nextPaymentAmount
                        : currentStudent.overduePlusNextPaymentAmount,
                });
            }

            setOtherAmountMinimum(1);
            setOtherAmountMaximum(currentStudent.overduePlusNextPaymentAmount);
            options.push({
                paymentMode: "partial",
                label: "Other amount",
                checkboxValue: "Other amount",
                amountDisplayValue: 0,
                amountValue: 0,
            });
        }
        setCheckboxOptions(options);
    }, [currentStudent]);

    useEffect(() => {
        if (checkboxOptions.length > 0) {
            setPaymentMode(
                currentStudentId,
                currentStudent.paymentMode || checkboxOptions[0].paymentMode
            );
            setAmountToPay(checkboxOptions[0].amountValue, currentStudentId);
        }
    }, [checkboxOptions]);

    useEffect(() => {
        async function fetchSchool() {
            const schoolResponse = await schoolService.getSchool(currentStudent!.studentId);
            setStudentSchool(schoolResponse);
        }

        fetchSchool().finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        // set the finix fraud token every time the payment flow begins
        if (currentSchool) {
            window.Finix.Auth(
                import.meta.env.VITE_FINIX_ENVIRONMENT,
                currentSchool.finixMerchantId,
                (sessionKey) => {
                    setFinixFraudToken(sessionKey);
                }
            );
        }

        if (currentStudent.partialPaymentAmount && currentStudent.partialPaymentAmount > 0) {
            const displayAmount = formatterNoDollarSign.format(currentStudent.partialPaymentAmount);
            setDisplayAmountToPay(displayAmount);
            setAmountToPay(currentStudent.partialPaymentAmount, currentStudentId);
        }
    }, [currentSchool, currentStudent, school]);

    const validatePartialPayment = (partialPaymentAmount: number): boolean => {
        // no amount entered
        if (partialPaymentAmount === undefined || partialPaymentAmount === 0) {
            setIsAmountError(true);
            setErrorMessage("Please enter an amount");
            return false;
        }
        // if they entered an amount that is greater than the amount due
        else if (partialPaymentAmount > otherAmountMaximum) {
            setIsAmountError(true);
            setErrorMessage(`Maximum amount is ${formatter.format(otherAmountMaximum)}`);
            return false;
        }
        // if they entered an amount that is less than the minimum amount
        else if (partialPaymentAmount < otherAmountMinimum) {
            setIsAmountError(true);
            setErrorMessage(`Minimum amount is ${formatter.format(otherAmountMinimum)}`);
            return false;
        } else {
            setIsAmountError(false);
            setErrorMessage("");
            return true;
        }
    };

    const validateDisabledStudentPaymentAmount = (amount: number): boolean => {
        if (amount === undefined || amount === 0) {
            setIsAmountError(true);
            setErrorMessage("Please enter an amount");
            return false;
        } else {
            setIsAmountError(false);
            setErrorMessage("");
            return true;
        }
    };

    const handleChangePaymentMode = (paymentMode: PaymentMode, amount: number) => {
        const selectedOption = checkboxOptions.find((option) => option.paymentMode === paymentMode);

        if (!selectedOption) {
            return;
        }

        if (selectedOption.paymentMode === "partial") {
            setPaymentMode(currentStudentId, selectedOption.paymentMode);
            setAmountToPay(amount, currentStudentId);
        } else {
            // clear partial payment input and errors if toggling to a different state.
            setPartialPaymentAmount(currentStudentId, 0);
            setDisplayAmountToPay("");
            setIsAmountError(false);
            setErrorMessage("");

            setPaymentMode(currentStudentId, selectedOption.paymentMode);
            setAmountToPay(selectedOption.amountValue, currentStudentId);
        }
    };

    const handleNavigate = () => {
        // Only validate input if they are not paying in full, or the account is disabled
        if (currentStudent.status === "disabled") {
            const isInputValid = validateDisabledStudentPaymentAmount(
                currentStudent.partialPaymentAmount
            );
            if (!isInputValid) {
                return;
            }
        }
        if (currentStudent.paymentMode === "partial") {
            const isInputValid = validatePartialPayment(currentStudent.partialPaymentAmount);
            if (!isInputValid) {
                return;
            }
        }

        isEdit ? navigate("/payment/summary") : navigate("/payment/method");
    };

    return (
        <MeadowContainer
            fullWidth
            noPadding
            showSimpleHeader={true}
            simpleHeaderText="Select an amount to pay"
            sx={meadowContainerSx}
        >
            <LoadingDialog open={isLoading} />
            <Box sx={innerContainerSx(isMobile)}>
                <Card sx={checkboxCardSx}>
                    {isStudentAccountDisabled && (
                        <DisabledInput
                            setDisplayAmountToPay={setDisplayAmountToPay}
                            isAmountError={isAmountError}
                            errorMessage={errorMessage}
                            displayAmountToPay={displayAmountToPay}
                            enableDecimalFormatting={enableDecimalFormatting}
                            setEnableDecimalFormatting={setEnableDecimalFormatting}
                            validatePaymentAmount={validateDisabledStudentPaymentAmount}
                        />
                    )}
                    {!isStudentAccountDisabled &&
                        checkboxOptions.map((option, index) =>
                            option.paymentMode === "partial" ? (
                                <Box sx={otherAmountContainerSx} key={option.paymentMode}>
                                    <FormLabel sx={radioWrapperSx}>
                                        <Box sx={otherAmountCheckboxContainerSx}>
                                            <Radio
                                                checked={currentStudent.paymentMode === "partial"}
                                                onChange={() => {
                                                    handleChangePaymentMode("partial", 0);
                                                }}
                                                value={option.label}
                                                name="radio-buttons"
                                                inputProps={{
                                                    "aria-label": option.label,
                                                }}
                                            />
                                            {option.label}
                                        </Box>
                                    </FormLabel>
                                    <TextField
                                        sx={inputSx}
                                        onChange={(e) => {
                                            const values = e.target.value as any;
                                            setPartialPaymentAmount(
                                                currentStudentId,
                                                values?.floatValue ?? 0
                                            );
                                            setDisplayAmountToPay(values?.formattedValue ?? "");
                                            setAmountToPay(
                                                values?.floatValue ?? 0,
                                                currentStudentId
                                            );
                                            validatePartialPayment(values?.floatValue ?? 0);
                                        }}
                                        inputMode="numeric"
                                        placeholder="Enter amount"
                                        error={isAmountError}
                                        helperText={errorMessage}
                                        onFocus={() => {
                                            //Disable decimal formatting when user clicks on the input field
                                            setEnableDecimalFormatting(false);
                                            handleChangePaymentMode("partial", 0);
                                        }}
                                        onBlur={() => setEnableDecimalFormatting(true)}
                                        value={displayAmountToPay}
                                        inputProps={{
                                            enableDecimalFormatting,
                                        }}
                                        InputProps={{
                                            inputComponent: NumericFormatter as any,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <div style={dollarSignSx}>$</div>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            ) : (
                                <FormLabel
                                    sx={index === 0 ? radioWrapperSx : radioWrapperBorderTopSx}
                                    key={option.paymentMode}
                                >
                                    <Box sx={checkboxSx}>
                                        <Radio
                                            checked={
                                                currentStudent.paymentMode === option.paymentMode
                                            }
                                            onChange={() => {
                                                handleChangePaymentMode(
                                                    option.paymentMode,
                                                    option.amountValue
                                                );
                                            }}
                                            value={option.checkboxValue}
                                            name="radio-buttons"
                                            inputProps={{ "aria-label": "Payment due" }}
                                        />
                                        {option.label}
                                    </Box>
                                    <Box sx={amountPaddingSx}>
                                        {formatter.format(option.amountDisplayValue)}
                                    </Box>
                                </FormLabel>
                            )
                        )}
                </Card>
                <Box sx={continueContainerSx}>
                    <Button
                        sx={continueButtonSx}
                        onClick={() => handleNavigate()}
                        variant="primary"
                    >
                        Continue
                    </Button>
                    <SecurePayment />
                </Box>
            </Box>
        </MeadowContainer>
    );
}
